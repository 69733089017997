<template>
    <ul>
        <li><a>{{ $t('Dashboard') }}</a></li>
        <li><a>{{ $t('Users') }}</a></li>
        <li><a :class="['has-sub-menu', showUsersGroups ? 'router-link-active' : '']" @click="showUsersGroups ? showUsersGroups = false : showUsersGroups = true">{{ $t("User types") }}</a></li>
        <ul v-if="showUsersGroups">
            <li v-for="userGroup in userGroups" :key="userGroup.id">
                <router-link :to="{ name: 'UserTypes', params: { group: userGroup.name }}">
                    <small>{{ userGroup.name }}</small>
                </router-link>
            </li>
        </ul>
        <li>
          <router-link :to="{ name: 'Translations' }">{{ $t("Translations") }}</router-link>
        </li>
    </ul>
</template>

<script>

export default {
    name: "SuperAdminSettingsPanel",
    data() {
        return {
            showUsersGroups: false,

        }
    },
    computed: {
        userGroups() {
            return this.$store.getters.userGroups
        },
    },
}
</script>

<style scoped>

</style>